import Repository from "../repository";

const resource = "/templates";

export default {
  get(templateId) {
    return Repository.get(`${resource}/get/${templateId}`);
  },
  list(shopId, headers = {}) {
    return Repository.get(`${resource}/list/${shopId}`, { headers: headers });
  },
  list_ssid(payload, headers = {}) {
    return Repository.post(`${resource}/list_ssid`, payload, { headers });
  },
  copy(payload) {
    return Repository.post(`${resource}/copy`, payload);
  },
  create(payload) {
    return Repository.post(`${resource}/create`, payload);
  },
  update(payload) {
    return Repository.put(`${resource}/update`, payload);
  },
  delete(templateId) {
    return Repository.delete(`${resource}/delete/${templateId}`);
  },
  update_sort(payload, headers = {}) {
    return Repository.post(`${resource}/update_sort`, payload, { headers });
  },
};
