import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";

const utilShopSite = {
  async setStore() {
    // ローディング表示
    store.dispatch("loadingIcon/showIcon");

    const site_array = [];
    const ShopSitesRepository = await repositoryFactory.get("shopSites");
    const params = new URLSearchParams();
    params.append("shop_id", store.getters["shops/currentShop"].id);

    await ShopSitesRepository.list(params)
      .then((response) => {
        if (response.data) {
          site_array.splice(0);
          Object.keys(response.data).forEach(function (key) {
            site_array.push({
              "id": response.data[key].id,
              "shop_id": response.data[key].shop_id,
              "site_id": response.data[key].site_id,
              "auth_url": response.data[key].auth_url,
              "loginid": response.data[key].loginid,
              "password": response.data[key].password,
              "is_origin": response.data[key].is_origin,
              "proxy_server": response.data[key].proxy_server,
              "config": response.data[key].config,
              "sort": response.data[key].sort,
              "is_active": response.data[key].is_active,
              "sites_id": response.data[key].sites_id,
              "sites_name": response.data[key].sites_name,
              "sites_short_name": response.data[key].sites_short_name,
              "sites_type": response.data[key].sites_type,
              "sites_com_name": response.data[key].sites_com_name,
              "sites_category": response.data[key].sites_category,
              "memo": JSON.parse(response.data[key].config).memo,
              "color": JSON.parse(response.data[key].config).color,
              "shop_group_id": JSON.parse(response.data[key].config)
                .shop_group_id,
              "name":
                response.data[key].sites_name +
                (JSON.parse(response.data[key].config).memo != ""
                  ? " [ " + JSON.parse(response.data[key].config).memo + " ] "
                  : ""),
            });
          });
        }
      })
      .catch((error) => {
        throw "ERROR:データ取得エラー (" + error + ")";
      });
    // console.log("site_array", site_array);

    await store.dispatch("shop_sites/setShopSiteList", site_array);

    // ローディング非表示
    store.dispatch("loadingIcon/hideIcon");

    return true;
  },
};

export default utilShopSite;
