<template>
  <div class="text-subtitle-2 pa-1 white--text red" v-show="props.show_flg">
    <span class="yellow--text font-bold">【注意】</span
    ><strong>「駅チカ！人気ランキング」</strong
    >をご利用の際、媒体の仕様上、セラピスト名・文章・コメント等に絵文字が含まれていると正しく連動できないためご注意ください。
  </div>
</template>

<script>
export default {
  props: ["show_flg"],
  setup(props, ctx) {
    return {
      props,
    };
  },
};
</script>
